
<template>
    <svg  class="svg-style" v-html="svgIcon">
    </svg>
</template>

<script setup>
    const props = defineProps(['type'])

    const headerSvgs = ['three_stone','three_stone_active', 'three_stone_active_green', 'vintage','five_stone','seven_stone','five_stone_active','five_stone_active_green','seven_stone_active','seven_stone_active_green',
                    'vintage_active', 'vintage_active_green', 'halo','halo_active','halo_active_green', 'side_stone','side_stone_active','side_stone_active_green', 
                    'solitaire','solitaire_active','solitaire_active_green', 'half_eternity','half_eternity_active', 'half_eternity_active_green',
                    'full_eternity','full_eternity_active','full_eternity_active_green','full_eternity_ring',
                    'full_eternity_ring_active','full_eternity_ring_active_green', 'couple_bands_ring', 'band_ring', 
                    'band_ring_active','band_ring_active_green', 'couple_bands_ring_active','couple_bands_ring_active_green', 'plain','plain_active', 'plain_active_green',
                    'curve','curve_active','curve_active_green', 'unique', 'unique_active','unique_active_green', 'ring', 'earring','hoop_earring', 'hoop_earring_active','hoop_earring_active_green',
                    'necklace', 'bracelet', 'tennis_bracelet', 'tennis_bracelet_active','tennis_bracelet_active_green', 'stud_earring_active', 'stud_earring','stud_earring_active_green', 'solitaire_necklace_active', 
                    'solitaire_necklace','solitaire_necklace_active_green', 'tennis_necklace_active', 'tennis_necklace','tennis_necklace_active_green', 'anniversary_ring', 
                    ,'fashion_earring_active','fashion_earring','anniversary_ring_active','fashion_earring_active_green','anniversary_ring_active_green','toi_et_moi_ring','toi_et_moi_ring_active','toi_et_moi_ring_active_green','fashion_bracelet','fashion_bracelet_active_green','fashion_necklace_active', 'fashion_necklace', 'fashion_necklace_active_green',
        'openable_bangle_active', 'openable_bangle_active_green', 'openable_bangle', 'hues_ring', 'hues_ring_active_green', 'hues_ring_active', 'Soiree_ring', 'Soiree_ring_active', 'Soiree_ring_active_green', 'Roses_portraits_ring', 'Roses_portraits_ring_active', 'Roses_portraits_ring_active_green', 'Soiree_earring', 'Soiree_earring_active', 'Soiree_earring_active_green',
        , 'stars', 'chosen_stars'
                    ]

    const svgIcon = computed(()=>{
    if(headerSvgs.includes(props.type)){
        return `<use xlink:href="/svg/style.svg#${props.type}"> </use>`
    }
})

</script>
<style >

</style>
