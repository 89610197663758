import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_gab9g3XuEx from "/opt/buildhome/repo/node_modules/nuxt-aos/dist/runtime/plugin.js";
import sentry_client_VdOeuw25Mb from "/opt/buildhome/repo/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import plugin_c1UCGf85vx from "/opt/buildhome/repo/modules/m/device-main/runtime/plugin.ts";
import plugin_zCk5SvrVBJ from "/opt/buildhome/repo/modules/m/nuxt-auth-sanctum-main/runtime/plugin.ts";
import switch_locale_path_ssr_5csfIgkrBP from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/opt/buildhome/repo/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import customFetch_5XQo2Q3J4W from "/opt/buildhome/repo/plugins/customFetch.js";
import cart_KnoRTcAkJx from "/opt/buildhome/repo/plugins/cart.js";
import currency_Gx1n4rkgt4 from "/opt/buildhome/repo/plugins/currency.js";
import google_analytics_client_vKtiHLcz65 from "/opt/buildhome/repo/plugins/google-analytics.client.js";
import toast_Zl2rZYKMaV from "/opt/buildhome/repo/plugins/toast.js";
import thirdparty_pAEkZSy0qS from "/opt/buildhome/repo/plugins/thirdparty.js";
import format_carat_WajAggjUtR from "/opt/buildhome/repo/plugins/format-carat.js";
import locationRedirect_LdUSgLXmHl from "/opt/buildhome/repo/plugins/locationRedirect.js";
import route_client_dD2t47vhQH from "/opt/buildhome/repo/plugins/route.client.js";
import plugin_AUP22rrBAc from "/opt/buildhome/repo/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import amplitude_qcoZpE5aTU from "/opt/buildhome/repo/plugins/amplitude.ts";
import aos_Rx1DQ3If9A from "/opt/buildhome/repo/plugins/aos.js";
import commonHeader_Ft7nWBOniT from "/opt/buildhome/repo/plugins/commonHeader.js";
import mixpanel_client_ue3jLrXjDP from "/opt/buildhome/repo/plugins/mixpanel.client.js";
import modalCleanup_client_IBebL9vlIR from "/opt/buildhome/repo/plugins/modalCleanup.client.js";
import sentry_3AyO8nEfhE from "/opt/buildhome/repo/plugins/sentry.ts";
import vue_tel_input_GukcJ5wu6r from "/opt/buildhome/repo/plugins/vue-tel-input.js";
import vue3_google_login_client_VB8LhUamqB from "/opt/buildhome/repo/plugins/vue3-google-login.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_gab9g3XuEx,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_c1UCGf85vx,
  plugin_zCk5SvrVBJ,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  customFetch_5XQo2Q3J4W,
  cart_KnoRTcAkJx,
  currency_Gx1n4rkgt4,
  google_analytics_client_vKtiHLcz65,
  toast_Zl2rZYKMaV,
  thirdparty_pAEkZSy0qS,
  format_carat_WajAggjUtR,
  locationRedirect_LdUSgLXmHl,
  route_client_dD2t47vhQH,
  plugin_AUP22rrBAc,
  amplitude_qcoZpE5aTU,
  aos_Rx1DQ3If9A,
  commonHeader_Ft7nWBOniT,
  mixpanel_client_ue3jLrXjDP,
  modalCleanup_client_IBebL9vlIR,
  sentry_3AyO8nEfhE,
  vue_tel_input_GukcJ5wu6r,
  vue3_google_login_client_VB8LhUamqB
]